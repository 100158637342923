/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";
import Layout from "./components/layout";
import SEO from "./components/seo";
import { formatPrice } from "../../helpers/propertyHelper";
import {
  getTranslatedValue,
  translateStatic,
} from "../../helpers/translationHelper";
import * as theme2Styles from "../theme2/scss/theme2.module.scss";

import Media from "./detail/common/Media";
import Feature from "./detail/common/Feature";
import Image from "./detail/common/Image";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import OpenStreetMap from "../../common/OpenStreetMap";

const Theme2PropertyDetails = (props) => {
  const { pageContext, result } = props;
  const { property, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          setting: display_settings,
          company: { currency: company_currency },
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_listing_location_map, 
    display_listing_contact_form, 
    display_watermark 
  } = display_settings;

  const headline = getTranslatedValue(
    property.translations,
    "headline",
    locale,
    defaultLocale
  );
  const description = getTranslatedValue(
    property.translations,
    "description",
    locale,
    defaultLocale
  );

  let seoTitle = `${property.reference}`;
  if (headline) {
    seoTitle = `${headline}-${property.reference}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />

      <div className={`${theme2Styles.detailsHeaderC}`}>
        <div className={theme2Styles.wrapper}>
          <div className={`${theme2Styles.detailsHeader}`}>
            <div className={theme2Styles.hLeft}>
              <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <span className={theme2Styles.loc}>{property.location}</span>
            </div>
            <div className={`${theme2Styles.hRight}`}>
              <span className={`${theme2Styles.detailsPrc}`}>
                {formatPrice(property, company_currency)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={`${theme2Styles.detailsBannerWithForm}`}>
        {(() => {
          if (property.pictures.length > 0) {
            return (
              <Media
                pictures={property.pictures}
                watermark={display_watermark}
              />
            );
          }
        })()}
        {(() => {
          if (display_listing_contact_form) {
            return (
              <div className={theme2Styles.wrapper}>
                <div className={`${theme2Styles.formArea}`}>
                  <div className={`${theme2Styles.contactUs}`}>
                    <h6>
                      {translateStatic("contact_us", locale, defaultLocale)}
                      <small>
                        {translateStatic(
                          "contact_form_text",
                          locale,
                          defaultLocale
                        )}
                      </small>
                    </h6>
                    <ContactFormContainer />
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </div>

      <div className={`${theme2Styles.mainContent} ${theme2Styles.pT40}`}>
        <div className={theme2Styles.wrapper}>
          {(() => {
            if (property.services.length > 0 || property.amenities.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Feature
                    services={property.services}
                    amenities={property.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.translations.length > 0 && description) {
              if (property.translations[0]?.description?.trim() != "") {
                return (
                  <div className={`${theme2Styles.propDetailsSec}`}>
                    <h3>
                      {translateStatic("description", locale, defaultLocale)}
                    </h3>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                );
              }
            }
          })()}

          {(() => {
            if (property.pictures.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Image
                    pictures={property.pictures}
                    watermark={display_watermark}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.videos.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Video
                    videos={property.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (property.documents.length > 0) {
              return (
                <div className={`${theme2Styles.propDetailsSec}`}>
                  <Document
                    documents={property.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {display_listing_location_map && property.latitude && property.longitude && 
          <div className={`${theme2Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="property" 
              listings={[property]} 
              latitude={parseFloat(property.latitude)} 
              longitude={parseFloat(property.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default Theme2PropertyDetails;

Theme2PropertyDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
